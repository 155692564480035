import './App.css';
import { ProfilePage } from './components/ProfilePage';
import 'bootstrap/dist/css/bootstrap.css';
import { auth, db, storage } from "./components/Firebase";
import { Toaster } from 'react-hot-toast';



function App() {
  return (
    <div className="App">
        <Toaster />
        <ProfilePage/>
    </div>
  );
}

export default App;
