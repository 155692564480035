import { Container, Row, Col } from "react-bootstrap";
import { Email, LinkedIn, PictureAsPdf, Apple, Android, GitHub, Language } from "@mui/icons-material";
import { useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";

export const ProfilePage = () => {
  const [hover, setHover] = useState("");

  const bioInfo = () => {
    return <span>Hello! My name is Doug and I'm a senior software engineer working for Sandia National Labs. Please feel free to contact me using the links above.</span>;
  };

  const storage = getStorage();

  const downloadRez = () => {
    toast.success("Getting Profile PDF");
    getDownloadURL(ref(storage, "dougBrown_resume.pdf"))
      .then((url) => {
        window.open(url, "_blank");
      })
      .catch((error) => {
        toast.error("Could not get profile...");
        console.log(`Error ${error}`);
        // Handle any errors
      });
  };

  return (
    <div className="d-flex flex-column align-items-center" style={{ height: "100vh", backgroundColor: "#175063", backgroundImage: `url(/backgroundv2.jpg)`, backgroundSize: "cover" }}>
      <div className="w-100 px-3 d-flex flex-column align-items-center" style={{ height: "100%", overflowY: "auto", overflowX: "auto", scrollbarWidth: "none" }}>
        <div className="w-100" style={{ maxWidth: "1200px" }}>
          <div className="w-100 d-flex flex-row justify-content-center" style={{}}>
            <img className="my-5" src={"/IMG_4242.jpg"} style={{ maxHeight: 500, maxWidth: "100%", borderRadius: "50px" }} />
          </div>
          <div className="w-100 d-flex flex-column align-items-center py-5" style={{ backgroundColor: "#83BEC0", borderRadius: "50px", marginBottom: "200px" }}>
            <div className="w-100 d-flex flex-row justify-content-center">
              <Email
                onClick={() => {
                  window.open("mailto:email@doug-brown.com?subject=Hello!&body=Cool website! My name is...", "_blank");
                  toast.success("Opening email application");
                }}
                onMouseEnter={() => {
                  setHover("email");
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                style={{ cursor: "pointer", backgroundColor: hover == "email" ? "white" : "grey", borderRadius: "10px", height: "50px", width: "50px" }}
                className={`mx-2 text-info p-1`}
              />
              <LinkedIn
                onClick={() => window.open("https://www.linkedin.com/in/douglas-brown-992513159/", "_blank")}
                onMouseEnter={() => {
                  setHover("linked");
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                style={{ cursor: "pointer", backgroundColor: hover == "linked" ? "white" : "grey", borderRadius: "10px", height: "50px", width: "50px" }}
                className={`mx-2 text-info p-1`}
              />
              <span
                onClick={() => {
                  downloadRez();
                }}
                onMouseEnter={() => {
                  setHover("res");
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                style={{ cursor: "pointer", backgroundColor: hover == "res" ? "white" : "grey", borderRadius: "10px", height: "50px" }}
                className={`mx-2 text-info p-1`}
              >
                Resume {<PictureAsPdf style={{ height: "40px", width: "40px" }} />}
              </span>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center my-3">
              <h1>Doug Brown</h1>
            </div>

            <div className="d-lg-none d-flex justify-content-center my-5 px-2">{bioInfo()}</div>
            <div className="d-lg-flex d-none justify-content-center w-50 ">{bioInfo()}</div>

            <div className="my-3 w-75">
              <h4 style={{ borderBottom: "1px solid black" }}>My Main Programming Areas</h4>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center my-2">
              <div className="mx-3">
                <h4 style={{ color: "" }}>Python</h4>
                <img className="" src={"/python.jpg"} style={{ maxHeight: "75px", paddingBottom: "10px" }} />
              </div>
              <img className="mx-3" src={"/cpp.png"} style={{ maxHeight: "75px", maxWidth: "50%" }} />
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center my-2">
              <img className="" src={"/firebase.png"} style={{ maxHeight: "75px", maxWidth: "50%" }} />
              <img className="" src={"/JavaScript-Symbol-1.png"} style={{ maxHeight: "75px", maxWidth: "50%" }} />
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center my-2">
              <img className="" src={"/Google-flutter-logo.png"} style={{ maxHeight: "75px", maxWidth: "50%" }} />
              <img className="mx-4" src={"/logo512.png"} style={{ maxHeight: "75px", maxWidth: "50%" }} />
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center my-2">
              <div style={{ maxWidth: "30%" }}>
                <h4 style={{ color: "" }}>NoSql</h4>
                <img className="" src={"/noSql.png"} style={{ maxHeight: "75px" }} />
              </div>

              <img className="" src={"/android.png"} style={{ maxHeight: "75px", maxWidth: "30%" }} />
              <img className="" src={"/apple.png"} style={{ maxHeight: "75px", maxWidth: "30%", borderRadius: "100px" }} />
            </div>

            <div className="my-3 w-75" style={{ paddingTop: "30px" }}>
              <h4 style={{ borderBottom: "1px solid black" }}>Portfolio</h4>
            </div>
            {/* <div className='d-flex flex-row justify-content-center align-items-center my-2'>
                            <span style={{marginLeft: '10px'}}>Checkout GitHub for some code I've written</span>
                            <GitHub className='text-info mx-2 p-1' 
                                onClick={()=> window.open("https://github.com/db460/ReactExample", "_blank")}
                                onMouseEnter={()=>{setHover('github')}}
                                onMouseLeave={()=>{setHover('')}}
                                style={{height: '50px', width: '50px', maxHeight: '125px', cursor: 'pointer', 
                                    backgroundColor: hover == 'github' ?'white' :'grey', borderRadius: '10px'}}/>
                        </div> */}
            <div className="w-100 d-flex flex-row justify-content-center align-items-center my-5">
              <span style={{ marginLeft: "10px" }}>Here's an example of a hobby project I developed: </span>
              <div style={{ maxWidth: "50%" }}>
                <Android
                  className="text-info mx-2 p-1 my-1"
                  onClick={() => window.open("https://play.google.com/store/apps/details?id=com.cherryhana.animeway", "_blank")}
                  onMouseEnter={() => {
                    setHover("android");
                  }}
                  onMouseLeave={() => {
                    setHover("");
                  }}
                  style={{ height: "50px", width: "50px", maxHeight: "125px", cursor: "pointer", backgroundColor: hover == "android" ? "white" : "grey", borderRadius: "10px" }}
                />
                <Apple
                  className="text-info mx-2 p-1 my-1"
                  onClick={() => window.open("https://apps.apple.com/ca/app/animeway-anime-guide/id6470202806", "_blank")}
                  onMouseEnter={() => {
                    setHover("apple");
                  }}
                  onMouseLeave={() => {
                    setHover("");
                  }}
                  style={{ height: "50px", width: "50px", maxHeight: "125px", cursor: "pointer", backgroundColor: hover == "apple" ? "white" : "grey", borderRadius: "10px" }}
                />
                <Language
                  className="text-info mx-2 p-1 my-1"
                  onClick={() => window.open("https://animeway.us/", "_blank")}
                  onMouseEnter={() => {
                    setHover("web");
                  }}
                  onMouseLeave={() => {
                    setHover("");
                  }}
                  style={{ height: "50px", width: "50px", maxHeight: "125px", cursor: "pointer", backgroundColor: hover == "web" ? "white" : "grey", borderRadius: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
